import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

import Header from './Header';
import Sidebar from './Sidebar';
import { drawerWidth } from 'utils/statics/constant';

// assets
import { setMenu } from 'store/slices/menuSlice';

// styles
const MainContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.grey
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({

    ...theme.typography.mainContent,
    ...(!open && {
        marginLeft: "-250px",
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        width: `calc(100% - ${drawerWidth}px)`,
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.menuSlice.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch(setMenu(!leftDrawerOpened));
    };

    useEffect(() => {
        dispatch(setMenu(!matchDownMd));
    }, [matchDownMd]);

    return (
        <MainContainer sx={{ display: 'flex' }}>
            <CssBaseline />

            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            <Main open={leftDrawerOpened}>
                <Outlet />
            </Main>
        </MainContainer>
    );
};

export default MainLayout;
