import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const GalleryBoardApi = createApi({
    reducerPath: 'GalleryBoardApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['GalleryBoard'],
    endpoints: (build) => ({
        getGalleryBoard: build.query({
            query: (params) => `${URL.GALLERY_BOARD_LIST}?${params}`,
            providesTags: ['LIST']
        }),
        getGalleryBoardDetails: build.query({
            query: (params) => `${URL.GALLERY_BOARD_DETAIL}/${params}`,
            providesTags: ['DETAIL']
        }),
        createGalleryBoard: build.mutation({
            query: (body) => ({
                url: `${URL.GALLERY_BOARD_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST']
        }),
        updateGalleryBoard: build.mutation({
            query: (body) => ({
                url: `${URL.GALLERY_BOARD_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        deleteGalleryBoard: build.mutation({
            query: (body) => ({
                url: `${URL.GALLERY_BOARD_DELETE}/${body}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST']
        })
    })
});

export const {
    useGetGalleryBoardQuery,
    useGetGalleryBoardDetailsQuery,
    useCreateGalleryBoardMutation,
    useUpdateGalleryBoardMutation,
    useDeleteGalleryBoardMutation
} = GalleryBoardApi;
