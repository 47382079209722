import React, { createContext, useContext, useState } from 'react';

const NoticeContext = createContext({ page: 0, size: 10, searchWrd: '', exposureYn: '' });

const NoticeProvider = ({ children }) => {
    const [options, setOptions] = useState({ page: 0, size: 10, searchWrd: '', exposureYn: '' });

    const value = {
        options,
        setOptions
    };

    return <NoticeContext.Provider value={value}>{children}</NoticeContext.Provider>;
};

const useNoticeContext = () => {
    return useContext(NoticeContext);
};

export { NoticeProvider, useNoticeContext };
