// REACT
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModalOptionsCancel } from 'store/slices/modalSlice';

// MUI
import { Modal, Alert, AlertTitle, Button, Grid } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { ModalInner } from './ModalInner';
import { ButtonWrapp } from 'components';
import { useTranslation } from 'react-i18next';

function CustomModal() {
    const { t } = useTranslation();
    const modalOptions = useSelector((state) => state.modalSlice);
    const dispatch = useDispatch();
    const [modalItems, setModalItems] = useState({});
    const { type, onOpen, title, description, onBtn, onBtnAction, onBtnTxt } = modalItems;

    useEffect(() => {
        setModalItems(modalOptions);
    }, [modalOptions]);

    return (
        <Modal open={onOpen ?? false} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <ModalInner>
                <Alert
                    severity={type === 'success' || type === 'error' || type === 'info' || type === 'warning' ? type : 'info'}
                    sx={{ padding: '0' }}
                >
                    {title && <AlertTitle>{title}</AlertTitle>}
                    {description ?? ''}
                </Alert>
                <ButtonWrapp>
                    {onBtn && (
                        <Button
                            onClick={() => (onBtnAction ? onBtnAction() : console.log('Please call onBtnAction'))}
                            variant="outlined"
                            autoFocus
                            sx={{ mt: 2 }}
                        >
                            {onBtnTxt}
                        </Button>
                    )}
                    <Button
                        variant="outlined"
                        size="small"
                        autoFocus
                        sx={{ border: `1px solid ${blueGrey[700]}`, color: blueGrey[700] }}
                        onClick={() => {
                            dispatch(setModalOptionsCancel());
                        }}
                    >
                        {t('act_confirm')}
                    </Button>
                </ButtonWrapp>
            </ModalInner>
        </Modal>
    );
}

export default CustomModal;
