import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { AdminApi } from 'api/manage/admin';
import { AdminProvider } from './context';

const List = Loadable(lazy(() => import('./list')));
const Create = Loadable(lazy(() => import('./create')));
const Edit = Loadable(lazy(() => import('./edit')));

const AdminConfig = {
    routes: [
        {
            path: 'admin',
            children: [
                {
                    path: '',
                    element: (
                        <AdminProvider>
                            <List />
                        </AdminProvider>
                    )
                },
                {
                    path: 'create',
                    element: (
                        <AdminProvider>
                            <Create />
                        </AdminProvider>
                    )
                },
                {
                    path: 'modify/:code',
                    element: (
                        <AdminProvider>
                            <Edit />
                        </AdminProvider>
                    )
                }
            ]
        }
    ],
    API: AdminApi
};

export default AdminConfig;
