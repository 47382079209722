import React, {createContext, useContext, useState} from 'react';

const PortfolioContext = createContext({page: 0, size: 10, searchWrd: '', exposureYn: ''});

const PortfolioProvider = ({children}) => {
    const [options, setOptions] = useState({page: 0, size: 10, searchWrd: '', exposureYn: ''});

    const value = {
        options,
        setOptions,
    };

    return (
        <PortfolioContext.Provider value={value}>
            {children}
        </PortfolioContext.Provider>
    );
};

const usePortfolioContext = () => {
    return useContext(PortfolioContext);
};

export {PortfolioProvider, usePortfolioContext};
