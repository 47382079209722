import { useState, useEffect } from 'react';
import NavGroup from './NavGroup';
import { useGetNavMenusQuery } from 'views/pages/MenuManagement/AdminMenu/AdminMenuApi';
import { ArrayToParentChildJson } from 'utils/functions/ArrayToParentChildJson';

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import i18next from 'i18next';
import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const theme = useTheme();
    const [menuNodes, setMenuNodes] = useState([]);
    const { data, isLoading } = useGetNavMenusQuery();
    const currentLanguage = i18next.language;

    useEffect(() => {
        setMenuNodes([]);

        if (!isLoading && data?.content?.length > 0) {
            setMenuNodes([]);
            setMenuNodes((prevNodes) => Object.assign([], prevNodes, ArrayToParentChildJson(JSON.parse(JSON.stringify(data?.content)))));
        } else {
            setMenuNodes([]);
        }
    }, [data, isLoading]);

    function TabPanel(props) {
        const { children, value, index } = props;

        return (
            <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired
    };

    return (
        <>
            {menuNodes.map((item, index) => (
                <Box sx={{ m: '1rem' }} key={`group_menu_${index}`}>
                    <Typography variant="span" sx={{ ...theme.typography.menuCaption }}>
                        {currentLanguage === 'kr' && item.menuNameKr ? item.menuNameKr : item.menuName}
                    </Typography>
                    <NavGroup item={item} />
                </Box>
            ))}
        </>
    );
};

export default MenuList;
