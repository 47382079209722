import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const PortfolioBoardApi = createApi({
    reducerPath: 'PortfolioBoardApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['PortfolioBoard'],
    endpoints: (build) => ({
        getPortfolioBoard: build.query({
            query: (params) => `${URL.PORTFOLIO_LIST}?${params}`,
            providesTags: ['LIST']
        }),
        getPortfolioBoardDetails: build.query({
            query: (params) => `${URL.PORTFOLIO_DETAIL}/${params}`,
            providesTags: ['DETAIL']
        }),
        createPortfolioBoard: build.mutation({
            query: (body) => ({
                url: `${URL.PORTFOLIO_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST']
        }),
        updatePortfolioBoard: build.mutation({
            query: (body) => ({
                url: `${URL.PORTFOLIO_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        deletePortfolioBoard: build.mutation({
            query: (body) => ({
                url: `${URL.PORTFOLIO_DELETE}/${body}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST']
        })
    })
});

export const {
    useGetPortfolioBoardQuery,
    useGetPortfolioBoardDetailsQuery,
    useCreatePortfolioBoardMutation,
    useUpdatePortfolioBoardMutation,
    useDeletePortfolioBoardMutation
} = PortfolioBoardApi;
