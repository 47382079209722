import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const YoutubeBoardApi = createApi({
    reducerPath: 'YoutubeBoardApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['YoutubeBoard'],
    endpoints: (build) => ({
        getYoutubeBoard: build.query({
            query: (params) => `${URL.YOUTUBE_BOARD_LIST}?${params}`,
            providesTags: ['LIST']
        }),
        getYoutubeBoardDetails: build.query({
            query: (params) => `${URL.YOUTUBE_BOARD_DETAIL}/${params}`,
            providesTags: ['DETAIL']
        }),
        createYoutubeBoard: build.mutation({
            query: (body) => ({
                url: `${URL.YOUTUBE_BOARD_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST']
        }),
        updateYoutubeBoard: build.mutation({
            query: (body) => ({
                url: `${URL.YOUTUBE_BOARD_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        deleteYoutubeBoard: build.mutation({
            query: (body) => ({
                url: `${URL.YOUTUBE_BOARD_DELETE}/${body}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST']
        })
    })
});

export const {
    useGetYoutubeBoardQuery,
    useGetYoutubeBoardDetailsQuery,
    useCreateYoutubeBoardMutation,
    useUpdateYoutubeBoardMutation,
    useDeleteYoutubeBoardMutation
} = YoutubeBoardApi;
