import React, {createContext, useContext, useState} from 'react';

const FaqContext = createContext({page: 0, size: 10, searchWrd: '', categoryCd: ''});

const FaqProvider = ({children}) => {
    const [options, setOptions] = useState({page: 0, size: 10, searchWrd: '', categoryCd: ''});

    const value = {
        options,
        setOptions,
    };

    return (
        <FaqContext.Provider value={value}>
            {children}
        </FaqContext.Provider>
    );
};

const useFaqContext = () => {
    return useContext(FaqContext);
};

export {FaqProvider, useFaqContext};
