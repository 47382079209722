import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Card, CardContent, CardHeader, Divider, Typography, styled } from '@mui/material';

const MainCardWrap = styled(Card)(({ theme, sx }) => ({
    ...theme.typography.boxBorder,
    ...sx
}));

const MainCard = forwardRef(
    ({ children, content = true, contentClass = '', contentSX = {}, secondary, shadow, sx = {}, title, ...others }, ref) => {
        return (
            <MainCardWrap ref={ref} {...others} sx={sx}>
                {title && <CardHeader title={<Typography variant="MainCardTitle">{title}</Typography>} action={secondary} />}
                {title && <Divider />}

                {content && <CardContent className={contentClass}>{children}</CardContent>}
                {!content && children}
            </MainCardWrap>
        );
    }
);

MainCard.propTypes = {
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default MainCard;
