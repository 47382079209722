import React, { forwardRef } from 'react';
import { Box } from '@mui/system';

export const ModalInner = forwardRef(({ children, width }, ref) => {
    return (
        <Box
            ref={ref}
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#FFFFFF',
                padding: '30px 25px',
                borderRadius: '5px',
                width: width || 400
            }}
        >
            {children}
        </Box>
    );
});
