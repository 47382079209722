import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { PopupApi } from 'api/content/popup';
import { PoppupProvider } from './context';

const List = Loadable(lazy(() => import('./list')));
const Create = Loadable(lazy(() => import('./create')));
const Edit = Loadable(lazy(() => import('./edit')));

const PopupConfig = {
    routes: [
        {
            path: 'popup',
            children: [
                {
                    path: '',
                    element: (
                        <PoppupProvider>
                            <List />
                        </PoppupProvider>
                    )
                },
                {
                    path: 'create',
                    element: (
                        <PoppupProvider>
                            <Create />
                        </PoppupProvider>
                    )
                },
                {
                    path: 'modify/:code',
                    element: (
                        <PoppupProvider>
                            <Edit />
                        </PoppupProvider>
                    )
                }
            ]
        }
    ],
    API: PopupApi
};

export default PopupConfig;
