import React, { createContext, useContext, useState } from 'react';

const BannerContext = createContext({ page: 0, size: 10, searchWrd: '', useYn: '', mediaType: '' });

const BannerProvider = ({ children }) => {
    const [options, setOptions] = useState({ page: 0, size: 10, searchWrd: '', useYn: '', mediaType: '' });

    const value = {
        options,
        setOptions
    };

    return <BannerContext.Provider value={value}>{children}</BannerContext.Provider>;
};

const useBannerContext = () => {
    return useContext(BannerContext);
};

export { BannerProvider, useBannerContext };
