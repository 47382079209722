import React, { createContext, useContext, useState } from 'react';

const AdminContext = createContext({ page: 0, size: 10, searchWrd: '', useYn: '' });

const AdminProvider = ({ children }) => {
    const [options, setOptions] = useState({ page: 0, size: 10, searchWrd: '', useYn: '' });

    const value = {
        options,
        setOptions
    };

    return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>;
};

const useAdminContext = () => {
    return useContext(AdminContext);
};

export { AdminProvider, useAdminContext };
