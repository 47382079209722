import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import {ContentMenuProvider} from './context';
import {ContentMenuApi} from "api/content/contentMenu";
const List = Loadable(lazy(() => import('./list')));
const Edit = Loadable(lazy(() => import('./edit')));

const ContentMenuConfig = {
    routes: [
        {
            path: 'content/menu',
            children: [
                {
                    path: '',
                    element: (
                        <ContentMenuProvider>
                            <List />
                        </ContentMenuProvider>
                    )
                },
                {
                    path: 'modify/:code',
                    element: (
                        <ContentMenuProvider>
                            <Edit />
                        </ContentMenuProvider>
                    )
                }
            ]
        }
    ],
    API: ContentMenuApi
};

export default ContentMenuConfig;
