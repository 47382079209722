import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { YoutubeBoardApi } from 'api/community/YoutubeBoardApi';
import { YoutubeProvider } from './context';

const List = Loadable(lazy(() => import('./list')));
const Create = Loadable(lazy(() => import('./create')));
const Edit = Loadable(lazy(() => import('./edit')));

const YoutubeBoardConfig = {
    routes: [
        {
            path: 'youtube',
            children: [
                {
                    path: '',
                    element: <YoutubeProvider><List /></YoutubeProvider>
                },
                {
                    path: 'create',
                    element: <YoutubeProvider><Create /></YoutubeProvider>
                },
                {
                    path: 'modify/:code',
                    element: <YoutubeProvider><Edit /></YoutubeProvider>
                }
            ]
        }
    ],
    API: YoutubeBoardApi
};

export default YoutubeBoardConfig;
