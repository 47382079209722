import React, {createContext, useContext, useState} from 'react';

const BoardMailTemplateContext = createContext({page: 0, size: 10, searchWrd: '', boardType: ''});

const BoardMailTemplateProvider = ({children}) => {
    const [options, setOptions] = useState({page: 0, size: 10, searchWrd: '', boardType: ''});

    const value = {
        options,
        setOptions,
    };

    return (
        <BoardMailTemplateContext.Provider value={value}>
            {children}
        </BoardMailTemplateContext.Provider>
    );
};

const useBoardMailTemplateContext = () => {
    return useContext(BoardMailTemplateContext);
};

export {BoardMailTemplateProvider, useBoardMailTemplateContext};
