import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { BoardMailApi } from 'api/content/boardMailTemplate';
import { BoardMailTemplateProvider } from './context';
const BoardMailListPage = Loadable(lazy(() => import('./list')));
const BoardMailEditPage = Loadable(lazy(() => import('./edit')));

const BoardMailConfig = {
    routes: [
        {
            path: 'board/mail',
            children: [
                {
                    path: '',
                    element: <BoardMailTemplateProvider><BoardMailListPage /></BoardMailTemplateProvider>
                },
                {
                    path: 'create',
                    element: <BoardMailTemplateProvider><BoardMailEditPage /></BoardMailTemplateProvider>
                },
                {
                    path: 'edit/:code',
                    element: <BoardMailTemplateProvider><BoardMailEditPage /></BoardMailTemplateProvider>
                }
            ]
        }
    ],
    API: BoardMailApi
};

export default BoardMailConfig;
