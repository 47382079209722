import { createSlice } from '@reduxjs/toolkit';

export const MenuSlice = createSlice({
    name: 'menuSlice',
    initialState: {
        isOpen: [], // for active default menu
        boxBoxShadow: 'rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem',
        borderRadius: '0.5rem',
        opened: true
    },
    reducers: {
        menuOpen: (state, action) => {
            let id = action.payload;
            state.isOpen = [id];
        },
        setMenu: (state, action) => {
            state.opened = action.payload;
        }
    }
});

export const { menuOpen, setMenu } = MenuSlice.actions;
export default MenuSlice.reducer;
