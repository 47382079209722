import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { Button, styled } from '@mui/material';

const BaseButton = styled(Button)(({ theme }) => ({
    backgroundImage: theme.palette.dark.button,
    ...theme.typography.actionBottom
}));

const CreateButton = ({ to, state, ...props }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <BaseButton startIcon={<AddIcon />} type="button" onClick={() => navigate(to)} {...props}>
            {t('act_create')}
        </BaseButton>
    );
};

export default CreateButton;
