// REACT
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Grid, TextField } from '@mui/material';
import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MultipleStopTwoToneIcon from '@mui/icons-material/MultipleStopTwoTone';

// COMPONENTS
import { FormCmmCodeSelect, SearchInput, Select } from 'components';

// FORM
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';

const CustomFilter = forwardRef(({ filters, setOptions, formDefaultValues, isLoading, isFetching, placeholder, onChange }, _) => {
    const { t } = useTranslation();
    const { control, handleSubmit } = useForm({
        defaultValues: formDefaultValues ?? { searchWrd: '' }
    });

    if (!filters) {
        filters = [];
    }

    //SEARCH EVENT
    const submit = (values) => {
        let conditions = {};
        Object.keys(values).forEach((key) => {
            conditions[key] = values[key] !== t('ALL') ? values[key] : '';
            if (conditions['startDate'] != undefined) {
                if (conditions['startDate'] == null) {
                    conditions['startDate'] = '';
                }
            }
            if (conditions['endDate'] != undefined) {
                if (conditions['endDate'] == null) {
                    conditions['endDate'] = '';
                }
            }
        });
        setOptions((prevState) => ({ ...prevState, ...conditions }));
    };

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Box sx={{ display: 'flex', gap: '10px', paddingBottom: '15px', justifyContent: 'flex-end' }}>
                {filters && filters.length > 0 && (
                    <Grid item display={'flex'} gap={1}>
                        {filters?.map((filter, index) => {
                            return (
                                <Grid key={`filter-${index}`} container display={'flex'} alignItems={'center'}>
                                    <Grid item sx={{ display: 'flex' }}>
                                        {filter?.type === 'select' && filter?.commonCode && (
                                            <Controller
                                                control={control}
                                                name={filter?.name}
                                                render={({ field }) => (
                                                    <FormCmmCodeSelect
                                                        all={filter?.value === 'ALL' ? true : false}
                                                        groupName={filter?.commonCode}
                                                        // label={t(filter?.label)}
                                                        disabled={isLoading || isFetching}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        )}

                                        {filter?.type === 'select' && filter?.customData && (
                                            <Controller
                                                control={control}
                                                name={filter?.name}
                                                render={({ field }) => (
                                                    <Select options={filter?.customData || []} type={'text'} {...field} />
                                                )}
                                            />
                                        )}

                                        {filter?.type === 'dateRangePicker' && (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Box display="flex" alignItems="center">
                                                    <Controller
                                                        control={control}
                                                        name={'startDate'}
                                                        defaultValue={''}
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                inputFormat="YYYY-MM-DD"
                                                                format={'YYYY-MM-DD'}
                                                                views={['year', 'month', 'day']}
                                                                value={field.value}
                                                                onChange={(date) => {
                                                                    if (date) {
                                                                        const formattedDate = dayjs(date).format('YYYY-MM-DD');
                                                                        field.onChange(formattedDate);
                                                                    } else {
                                                                        field.onChange('');
                                                                    }
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField sx={{ width: '200px' }} size="small" {...params} />
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                    <MultipleStopTwoToneIcon fontSize="small" />

                                                    <Controller
                                                        control={control}
                                                        name={'endDate'}
                                                        defaultValue={''}
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                inputFormat="YYYY-MM-DD"
                                                                format={'YYYY-MM-DD'}
                                                                views={['year', 'month', 'day']}
                                                                value={field.value}
                                                                onChange={(date) => {
                                                                    if (date) {
                                                                        const formattedDate = dayjs(date).format('YYYY-MM-DD');
                                                                        field.onChange(formattedDate);
                                                                    } else {
                                                                        field.onChange('');
                                                                    }
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField sx={{ width: '200px' }} size="small" {...params} />
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                </Box>
                                            </LocalizationProvider>
                                        )}
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                )}

                <Grid item>
                    <Controller
                        control={control}
                        name="searchWrd"
                        render={({ field }) => (
                            <SearchInput placeholder={placeholder} size="small" disabled={isLoading || isFetching} {...field} />
                        )}
                    />
                </Grid>
            </Box>
        </form>
    );
});

export default CustomFilter;
