import React, {createContext, useContext, useState} from 'react';

const ReviewContext = createContext({page: 0, size: 10, searchWrd: '', divisionCd: ''});

const ReviewProvider = ({children}) => {
    const [options, setOptions] = useState({page: 0, size: 10, searchWrd: '', divisionCd: ''});

    const value = {
        options,
        setOptions,
    };

    return (
        <ReviewContext.Provider value={value}>
            {children}
        </ReviewContext.Provider>
    );
};

const useReviewContext = () => {
    return useContext(ReviewContext);
};

export {ReviewProvider, useReviewContext};
