import React, {createContext, useContext, useState} from 'react';

const GalleryContext = createContext({page: 0, size: 10, searchWrd: '', exposureYn: ''});

const GalleryProvider = ({children}) => {
    const [options, setOptions] = useState({page: 0, size: 10, searchWrd: '', exposureYn: ''});

    const value = {
        options,
        setOptions,
    };

    return (
        <GalleryContext.Provider value={value}>
            {children}
        </GalleryContext.Provider>
    );
};

const useGalleryContext = () => {
    return useContext(GalleryContext);
};

export {GalleryProvider, useGalleryContext};
