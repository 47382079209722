import React, {createContext, useContext, useState} from 'react';

const InquiryContext = createContext({page: 0, size: 10, searchWrd: '', contactedYn: ''});

const InquiryProvider = ({children}) => {
    const [options, setOptions] = useState({page: 0, size: 10, searchWrd: '', contactedYn: ''});

    const value = {
        options,
        setOptions,
    };

    return (
        <InquiryContext.Provider value={value}>
            {children}
        </InquiryContext.Provider>
    );
};

const useInquiryContext = () => {
    return useContext(InquiryContext);
};

export {InquiryProvider, useInquiryContext};
