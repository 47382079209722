export default function themeTypography(theme) {
    return {
        fontFamily: theme?.fontFamily,
        MainCardTitle: {
            fontSize: '1.25rem',
            color: theme.heading,
            fontWeight: 600
        },
        subtitle1: {
            fontSize: '0.875rem',
            fontWeight: 500,
            color: theme.textDark
        },
        subtitle2: {
            fontSize: '0.75rem',
            fontWeight: 400,
            color: theme.darkTextSecondary
        },
        h3: {
            fontSize: '1.25rem',
            color: theme.heading,
            fontWeight: 600
        },
        // h6: {
        //     fontWeight: 500,
        //     color: theme.heading,
        //     fontSize: '0.75rem'
        // },
        // h5: {
        //     fontSize: '0.875rem',
        //     color: theme.heading,
        //     fontWeight: 500
        // },
        // h4: {
        //     fontSize: '1rem',
        //     color: theme.heading,
        //     fontWeight: 600
        // },
        // h3: {
        //     fontSize: '1.25rem',
        //     color: theme.heading,
        //     fontWeight: 600
        // },
        // h2: {
        //     fontSize: '1.5rem',
        //     color: theme.heading,
        //     fontWeight: 700
        // },
        // h1: {
        //     fontSize: '2.125rem',
        //     color: theme.heading,
        //     fontWeight: 700
        // },

        caption: {
            fontSize: '0.75rem',
            color: theme.darkTextSecondary,
            fontWeight: 400
        },
        body1: {
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.334em'
        },
        body2: {
            letterSpacing: '0em',
            fontWeight: 400,
            lineHeight: '1.5em',
            color: theme.darkTextPrimary
        },
        button: {
            textTransform: 'capitalize'
        },
        customInput: {
            marginTop: 1,
            marginBottom: 1,
            '& > label': {
                top: 23,
                left: 0,
                color: theme.grey500,
                '&[data-shrink="false"]': {
                    top: 5
                }
            },
            '& > div > input': {
                padding: '30.5px 14px 11.5px !important'
            },
            '& legend': {
                display: 'none'
            },
            '& fieldset': {
                top: 0
            }
        },
        mainContent: {
            backgroundColor: theme.colors.greyPaper,
            width: '100%',
            minHeight: 'calc(100vh - 69px)',
            flexGrow: 1,
            padding: '30px 20px',
            marginTop: '80px',
            borderRadius: `${theme?.secondBorderRadius}px`
        },
        menuCaption: {
            paddingLeft: '24px',
            textTransform: 'uppercase',
            lineHeight: '1.25',
            fontSize: '0.75rem',
            color: theme.colors.darkTextHint2,
            opacity: '0.6',
            fontWeight: 700
        },
        subMenuCaption: {
            fontSize: '0.6875rem',
            fontWeight: 400,
            color: theme.colors.darkTextHint2,
            textTransform: 'capitalize'
        },
        smallAvatar: {
            width: '22px',
            height: '22px',
            fontSize: '1rem'
        },
        mediumAvatar: {
            width: '34px',
            height: '34px',
            fontSize: '1.2rem',
            cursor: 'pointer'
        },
        largeAvatar: {
            width: '44px',
            height: '44px',
            fontSize: '1.5rem',
            cursor: 'pointer'
        },
        boxBoxShadow: {
            boxShadow: theme.boxBoxShadow
        },
        boxBorder: {
            boxShadow: theme.boxBoxShadow,
            backgroundColor: theme.colors.paper,
            border: '0px solid rgba(0, 0, 0, 0.125)',
            borderRadius: theme.borderRadius,
            overflowWrap: 'break-word',
            overflow: 'hidden'
        },
        actionBottom: {
            height: 41,
            minWidth: 150,
            fontWeight: 600,
            color: theme.paper,
            textTransform: 'uppercase',
            fontSize: '0.75rem',
            boxShadow: 'rgba(0, 0, 0, 0.11) 0rem 0.25rem 0.4375rem -0.0625rem, rgba(0, 0, 0, 0.07) 0rem 0.125rem 0.25rem -0.0625rem',
            '& .MuiButton-startIcon .MuiSvgIcon-root': {
                fontSize: 16
            }
        },
        gnb: {
            fontSize: '0.875rem',
            color: theme.heading,
            fontWeight: 500
        },
        gnb_active: {
            fontWeight: 700,
            color: 'red'
        },
        snb: {
            fontSize: '0.875rem',
            color: theme.heading,
            fontWeight: 400
        }
    };
}
