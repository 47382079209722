import React, {createContext, useContext, useState} from 'react';

const StaffContext = createContext({page: 0, size: 10, searchWrd: '', useYn: ''});

const StaffProvider = ({children}) => {
    const [options, setOptions] = useState({page: 0, size: 10, searchWrd: '', useYn: ''});

    const value = {
        options,
        setOptions,
    };

    return (
        <StaffContext.Provider value={value}>
            {children}
        </StaffContext.Provider>
    );
};

const useStaffContext = () => {
    return useContext(StaffContext);
};

export {StaffProvider, useStaffContext};
