import React, {createContext, useContext, useState} from 'react';

const YoutubeContext = createContext({page: 0, size: 10, searchWrd: '', exposureYn: ''});

const YoutubeProvider = ({children}) => {
    const [options, setOptions] = useState({page: 0, size: 10, searchWrd: '', exposureYn: ''});

    const value = {
        options,
        setOptions,
    };

    return (
        <YoutubeContext.Provider value={value}>
            {children}
        </YoutubeContext.Provider>
    );
};

const useYoutubeContext = () => {
    return useContext(YoutubeContext);
};

export {YoutubeProvider, useYoutubeContext};
