import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const BoardMailApi = createApi({
    reducerPath: 'BoardMailApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['BoardMails'],
    endpoints: (build) => ({
        getBoardMails: build.query({
            query: (params) => `${URL.BOARD_MAIL_LIST}?${params}`,
            providesTags: ['LIST']
        }),
        getBoardMail: build.query({
            query: (params) => `${URL.BOARD_MAIL_LIST}/${params}`,
            providesTags: ['DETAIL']
        }),
        createBoardMail: build.mutation({
            query: (body) => ({
                url: `${URL.BOARD_MAIL_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST']
        }),
        updateBoardMail: build.mutation({
            query: (body) => ({
                url: `${URL.BOARD_MAIL_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        deleteBoardMail: build.mutation({
            query: (body) => ({
                url: `${URL.BOARD_MAIL_DELETE}/${body}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST']
        })
    })
});

export const { useGetBoardMailsQuery, useCreateBoardMailMutation, useUpdateBoardMailMutation, useDeleteBoardMailMutation, useGetBoardMailQuery } = BoardMailApi;
