import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const ReviewApi = createApi({
    reducerPath: 'ReviewApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['Review'],
    endpoints: (build) => ({
        getReview: build.query({
            query: (params) => `${URL.REVIEW_BOARD_LIST}?${params}`,
            providesTags: ['LIST']
        }),
        getReviewDetails: build.query({
            query: (params) => `${URL.REVIEW_BOARD_DETAIL}/${params}`,
            providesTags: ['LIST']
        }),
        createReview: build.mutation({
            query: (body) => ({
                url: `${URL.REVIEW_BOARD_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST']
        }),
        updateReview: build.mutation({
            query: (body) => ({
                url: `${URL.REVIEW_BOARD_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST']
        }),
        deleteReview: build.mutation({
            query: (body) => ({
                url: `${URL.REVIEW_BOARD_DELETE}/${body}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST']
        }),
        deleteReviews: build.mutation({
            query: (body) => ({
                url: `${URL.REVIEW_BOARD_DELETE}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST']
        })
    })
});

export const {
    useGetReviewQuery,
    useGetReviewDetailsQuery,
    useCreateReviewMutation,
    useUpdateReviewMutation,
    useDeleteReviewMutation,
    useDeleteReviewsMutation
} = ReviewApi;
