import { Grid } from '@mui/material';

const ButtonWrapp = ({ children, justifyContent, gap, mt, sx }) => {
    return (
        <Grid display={'flex'} gap={gap || 1} justifyContent={justifyContent || 'flex-end'} marginTop={mt || '20px'} sx={sx}>
            {children}
        </Grid>
    );
};

export default ButtonWrapp;
