import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { GalleryBoardApi } from 'api/community/GalleryBoardApi';
import { GalleryProvider } from './context';

const List = Loadable(lazy(() => import('./list')));
const Create = Loadable(lazy(() => import('./create')));
const Edit = Loadable(lazy(() => import('./edit')));

const GalleryBoardConfig = {
    routes: [
        {
            path: 'gallery',
            children: [
                {
                    path: '',
                    element: <GalleryProvider><List /></GalleryProvider>
                },
                {
                    path: 'create',
                    element: <GalleryProvider><Create /></GalleryProvider>
                },
                {
                    path: 'modify/:code',
                    element: <GalleryProvider><Edit /></GalleryProvider>
                }
            ]
        }
    ],
    API: GalleryBoardApi
};

export default GalleryBoardConfig;
