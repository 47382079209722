// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, styled, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import { drawerWidth } from 'utils/statics/constant';
import React from "react";

// ==============================|| SIDEBAR DRAWER ||============================== //

const SideBarMenu = styled(Box)(({ open, matchupmd }) => ({
    // flexShrink: { md: 0 },
    width: matchupmd ? drawerWidth : 0,
}));

const MenuDrawer = styled(Drawer)(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        ...theme.typography.boxBoxShadow,
        width: open ? drawerWidth : '0',
        backgroundColor: 'transparent',
        borderRight: 'none',
        top: '69px',
        ...(!open && {
            width: '0',
        })
    }
}));

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const theme = useTheme();
    const matchupmd = useMediaQuery(theme.breakpoints.up('md')).toString();
    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <SideBarMenu component="nav" matchupmd={matchupmd}>
            <MenuDrawer
                container={container}
                variant={matchupmd ? 'permanent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                <BrowserView>
                    <PerfectScrollbar
                        component="div"
                        style={{
                            height: !matchupmd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)'
                        }}
                    >
                        <MenuList drawerOpen={drawerOpen} />
                    </PerfectScrollbar>
                </BrowserView>
            </MenuDrawer>
        </SideBarMenu>
    );
};

export default Sidebar;
