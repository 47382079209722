import { Box, Table as MuiTable, TableBody } from '@mui/material';

const Table = ({ children, reqtext, ...props }) => {
    return (
        <Box>
            {!reqtext && (
                <div style={{ textAlign: 'end' }}>
                    <span className="reqItem">*</span> 필수 입력 항목입니다.
                </div>
            )}

            <MuiTable {...props}>
                <TableBody style={{ borderTop: '1px solid #e0e0e0', borderLeft: '1px solid #e0e0e0' }}>{children}</TableBody>
            </MuiTable>
        </Box>
    );
};

export default Table;
