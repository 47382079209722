import React, { lazy } from 'react';
import { ContactApi } from 'api/community/ContactApi';
import Loadable from 'components/loading/Loadable';
import { ContactProvider } from "./context";

const List = Loadable(lazy(() => import('./list')));
const Edit = Loadable(lazy(() => import('./edit')));

const ContactConfig = {
    routes: [
        {
            path: 'contact',
            children: [
                {
                    path: '',
                    element: <ContactProvider><List /></ContactProvider>
                },

                {
                    path: 'modify/:code',
                    element: <ContactProvider><Edit /></ContactProvider>
                }
            ]
        }
    ],
    API: ContactApi
};

export default ContactConfig;
