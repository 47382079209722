import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const StaffBoardApi = createApi({
    reducerPath: 'StaffBoardApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['StaffBoard'],
    endpoints: (build) => ({
        getStaffBoard: build.query({
            query: (params) => `${URL.STAFF_BOARD_LIST}?${params}`,
            providesTags: ['LIST']
        }),
        getStaffBoardDetails: build.query({
            query: (params) => `${URL.STAFF_BOARD_DETAIL}/${params}`,
            providesTags: ['DETAIL']
        }),
        createStaffBoard: build.mutation({
            query: (body) => ({
                url: `${URL.STAFF_BOARD_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST']
        }),
        updateStaffBoard: build.mutation({
            query: (body) => ({
                url: `${URL.STAFF_BOARD_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST', 'DETAIL']
        }),
        deleteStaffBoard: build.mutation({
            query: (body) => ({
                url: `${URL.STAFF_BOARD_DELETE}/${body}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST']
        })
    })
});

export const {
    useGetStaffBoardQuery,
    useGetStaffBoardDetailsQuery,
    useCreateStaffBoardMutation,
    useUpdateStaffBoardMutation,
    useDeleteStaffBoardMutation
} = StaffBoardApi;
