import React, {createContext, useContext, useState} from 'react';

const CertContext = createContext({page: 0, size: 10, searchWrd: '', exposureYn: ''});

const CertProvider = ({children}) => {
    const [options, setOptions] = useState({page: 0, size: 10, searchWrd: '', exposureYn: ''});

    const value = {
        options,
        setOptions,
    };

    return (
        <CertContext.Provider value={value}>
            {children}
        </CertContext.Provider>
    );
};

const useCertContext = () => {
    return useContext(CertContext);
};

export {CertProvider, useCertContext};
