import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
// Components
import { DeleteConfirm } from 'components';
import Snackbar from 'components/Snackbar';
import { Button, CircularProgress, styled } from '@mui/material';
import { DeleteForeverOutlined } from '@mui/icons-material';
import { useEffect } from 'react';
import { t } from 'i18next';
import resultChecker from 'utils/functions/resultChecker';

const BaseButton = styled(Button)(({ theme }) => ({
    backgroundImage: theme.palette.error.button,
    ...theme.typography.actionBottom
}));
const SelectedDeleteButton = ({ loading, deleteFunc, to, codeGroup, size, selectedRows, ...props }) => {
    const [seqs, setSeqs] = useState([]);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [onDelete, deleteResult] = deleteFunc();

    const handleSelectedDelete = () => {
        if (selectedRows?.length > 0) {
            let newArray = [];
            selectedRows?.map((item) => {
                let mergeArray = [];
                let seq = item?.map((detail) => {
                    return detail?.seq;
                });
                mergeArray = [...mergeArray, ...seq];
                return (newArray = [...newArray, ...mergeArray]);
            });
            setSeqs(newArray);
            setShow(true);
        } else {
            Snackbar.warning(t('please_check_row_delete'));
        }
    };

    const handleDelete = useCallback(
        (values) => {
            let formData = { ids: values };
            onDelete(formData);
        },
        [navigate, onDelete]
    );

    useEffect(() => {
        if (deleteResult?.data && !deleteResult?.isError) {
            let res = deleteResult;
            resultChecker({ res, redirect: () => setShow(false), type: 'delete' });
        } else if (deleteResult?.isError) {
            console.log('DELETE API ERROR');
        }
        return () => {};
    }, [deleteResult]);

    return (
        <>
            <BaseButton
                type="button"
                startIcon={loading ? <CircularProgress size={20} color="light" /> : <DeleteForeverOutlined />}
                onClick={handleSelectedDelete}
                {...props}
            >
                {t('act_delete')}
            </BaseButton>
            <DeleteConfirm
                show={show}
                setShow={setShow}
                isLoading={loading || deleteResult?.isLoading}
                onDelete={handleDelete}
                code={seqs}
            />
        </>
    );
};

export default SelectedDeleteButton;
