import { createApi } from '@reduxjs/toolkit/query/react';
import { Api } from 'api';
import { URL } from 'api/ApiUrls';

export const ContactApi = createApi({
    reducerPath: 'ContactApi',
    baseQuery: Api.fetchBaseQuery,
    tagTypes: ['Contacts'],
    endpoints: (build) => ({
        getContacts: build.query({
            query: (params) => `${URL.CONTACT_LIST}?${params}`,
            providesTags: ['LIST']
        }),
        getContact: build.query({
            query: (params) => `${URL.CONTACT_DETAIL}/${params}`,
            providesTags: ['LIST']
        }),
        createContact: build.mutation({
            query: (body) => ({
                url: `${URL.CONTACT_CREATE}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['LIST']
        }),
        updateContact: build.mutation({
            query: (body) => ({
                url: `${URL.CONTACT_UPDATE}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['LIST']
        }),
        deleteContact: build.mutation({
            query: (body) => ({
                url: `${URL.CONTACT_DELETE}/${body}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST']
        }),
        deleteContacts: build.mutation({
            query: (body) => ({
                url: `${URL.CONTACT_DELETE}`,
                method: 'DELETE',
                body: body
            }),
            invalidatesTags: ['LIST']
        })
    })
});

export const { useGetContactsQuery, useCreateContactMutation, useUpdateContactMutation, useDeleteContactMutation, useGetContactQuery, useDeleteContactsMutation } =
    ContactApi;
