import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { CommonCodeApi } from 'api/common/code';

const CommonCodeList = Loadable(lazy(() => import('./list')));

const CommonCodeConfig = {
    routes: [
        {
            path: '',
            children: [
                {
                    path: 'code',
                    element: <CommonCodeList />
                }
            ]
        }
    ],
    API: CommonCodeApi
};

export default CommonCodeConfig;
