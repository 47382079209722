import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { PortfolioBoardApi } from 'api/community/PortfolioBoardApi';
import {PortfolioProvider} from "./context";

const List = Loadable(lazy(() => import('./list')));
const Create = Loadable(lazy(() => import('./create')));
const Edit = Loadable(lazy(() => import('./edit')));

const PortfolioBoardConfig = {
    routes: [
        {
            path: 'portfolio',
            children: [
                {
                    path: '',
                    element: <PortfolioProvider><List /></PortfolioProvider>
                },
                {
                    path: 'create',
                    element: <PortfolioProvider><Create /></PortfolioProvider>
                },
                {
                    path: 'modify/:code',
                    element: <PortfolioProvider><Edit /></PortfolioProvider>
                }
            ]
        }
    ],
    API: PortfolioBoardApi
};

export default PortfolioBoardConfig;
