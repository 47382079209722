import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { Button, Modal, Typography } from '@mui/material';
import { IconCheck } from '@tabler/icons';
import { useCallback } from 'react';
import { ButtonWrapp } from 'components';
import { ModalInner } from './ModalInner';

export const DeleteConfirm = ({ show, setShow, isLoading, onDelete, code, codeGroup }) => {
    const { t } = useTranslation();

    const onClose = useCallback(() => {
        if (isLoading) return;
        setShow(false);
    }, [isLoading, setShow]);

    return (
        <Modal open={show} onClose={onClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <ModalInner>
                <Typography id="parent-modal-description">{t('ask_to_delete')}</Typography>
                <ButtonWrapp sx={{ marginTop: '20px' }}>
                    <LoadingButton
                        loading={isLoading}
                        disabled={isLoading}
                        size="small"
                        loadingPosition="start"
                        startIcon={<IconCheck />}
                        variant="contained"
                        autoFocus
                        onClick={(e) => {
                            if (codeGroup) {
                                onDelete({ code, codeGroup });
                            } else {
                                onDelete(code);
                            }
                        }}
                    >
                        {t('act_confirm')}
                    </LoadingButton>
                    <Button
                        disableElevation
                        variant="outlined"
                        color="primary"
                        size="small"
                        disabled={isLoading}
                        onClick={(e) => {
                            setShow(false);
                        }}
                    >
                        {t('act_cancel')}
                    </Button>
                </ButtonWrapp>
            </ModalInner>
        </Modal>
    );
};
