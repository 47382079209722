import React, { createContext, useContext, useState } from 'react';

const ContentMenuContext = createContext({ page: 0, size: 10, searchWrd: '', useYn: '' });

const ContentMenuProvider = ({ children }) => {
    const [options, setOptions] = useState({ page: 0, size: 10, searchWrd: '', useYn: ''});

    const value = {
        options,
        setOptions
    };

    return <ContentMenuContext.Provider value={value}>{children}</ContentMenuContext.Provider>;
};

const useContentMenuContext = () => {
    return useContext(ContentMenuContext);
};

export { ContentMenuProvider, useContentMenuContext };
