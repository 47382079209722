import React, { lazy } from 'react';
import { InquiryApi } from 'api/community/InquiryApi';
import Loadable from 'components/loading/Loadable';
import {InquiryProvider} from "./context";

const List = Loadable(lazy(() => import('./list')));
const View = Loadable(lazy(() => import('./view')));

const InquiryConfig = {
    routes: [
        {
            path: 'inquiry',
            children: [
                {
                    path: '',
                    element: <InquiryProvider><List /></InquiryProvider>
                },
                {
                    path: 'modify/:code',
                    element: <InquiryProvider><View /></InquiryProvider>
                }
            ]
        }
    ],
    API: InquiryApi
};

export default InquiryConfig;
