import React, { lazy } from 'react';
import Loadable from 'components/loading/Loadable';
import { BannerApi } from 'api/content/banner';
import { BannerProvider } from './context';

const List = Loadable(lazy(() => import('./list')));
const Create = Loadable(lazy(() => import('./create')));
const Edit = Loadable(lazy(() => import('./edit')));

const BannerConfig = {
    routes: [
        {
            path: 'banner',
            children: [
                {
                    path: '',
                    element: (
                        <BannerProvider>
                            <List />
                        </BannerProvider>
                    )
                },
                {
                    path: 'create',
                    element: (
                        <BannerProvider>
                            <Create />
                        </BannerProvider>
                    )
                },
                {
                    path: 'modify/:code',
                    element: (
                        <BannerProvider>
                            <Edit />
                        </BannerProvider>
                    )
                }
            ]
        }
    ],
    API: BannerApi
};

export default BannerConfig;
