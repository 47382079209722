import React, { lazy } from 'react';
import { QnaApi } from 'api/community/QnaApi';
import Loadable from 'components/loading/Loadable';
import { QnaProvider } from './context';

const List = Loadable(lazy(() => import('./list')));
const Edit = Loadable(lazy(() => import('./edit')));

const QnaConfig = {
    routes: [
        {
            path: 'qna',
            children: [
                {
                    path: '',
                    element: <QnaProvider><List /></QnaProvider>
                },
                {
                    path: 'modify/:code',
                    element: <QnaProvider><Edit /></QnaProvider>
                }
            ]
        }
    ],
    API: QnaApi
};

export default QnaConfig;
