import React, { lazy } from 'react';
import { FaqApi } from 'api/community/FaqApi';
import Loadable from 'components/loading/Loadable';
import { FaqProvider } from './context';

const List = Loadable(lazy(() => import('./list')));
const Create = Loadable(lazy(() => import('./create')));
const Edit = Loadable(lazy(() => import('./edit')));

const FaqConfig = {
    routes: [
        {
            path: 'Faq',
            children: [
                {
                    path: '',
                    element: <FaqProvider><List /></FaqProvider>
                },
                {
                    path: 'create',
                    element: <FaqProvider><Create /></FaqProvider>
                },
                {
                    path: 'modify/:code',
                    element: <FaqProvider><Edit /></FaqProvider>
                }
            ]
        }
    ],
    API: FaqApi
};

export default FaqConfig;
