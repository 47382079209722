import React, {createContext, useContext, useState} from 'react';

const ContactContext = createContext({page: 0, size: 10, searchWrd: '', contactCd: ''});

const ContactProvider = ({children}) => {
    const [options, setOptions] = useState({page: 0, size: 10, searchWrd: '', contactCd: ''});

    const value = {
        options,
        setOptions,
    };

    return (
        <ContactContext.Provider value={value}>
            {children}
        </ContactContext.Provider>
    );
};

const useContactContext = () => {
    return useContext(ContactContext);
};

export {ContactProvider, useContactContext};
