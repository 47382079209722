import { Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ModalInner } from './ModalInner';
import { LoadingButton } from '@mui/lab';
import { IconCheck } from '@tabler/icons';
import { ButtonWrapp } from 'components';

const AlertModal = ({ open, close, text }) => {
    const { t } = useTranslation();

    return (
        <Modal open={open} onClose={close} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <ModalInner>
                <Typography>{text}</Typography>
                <ButtonWrapp sx={{ marginTop: '20px' }}>
                    <LoadingButton size="small" startIcon={<IconCheck />} variant="contained" autoFocus onClick={close}>
                        {t('act_confirm')}
                    </LoadingButton>
                </ButtonWrapp>
            </ModalInner>
        </Modal>
    );
};

export default AlertModal;
